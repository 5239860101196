import { nanoid } from "nanoid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SkittyBopSDK } from "./sdk";
import { Modal, Button, Row, Col, Container } from "react-bootstrap";
import "./styles/App.css";

interface IUsers {
    user: string,
    status: string
}

const contraints = { video: true, audio: true };

const App = () => {
    const localStream = useRef<HTMLVideoElement>(null);
    const remoteStream = useRef<HTMLVideoElement>(null);
    const webrtc = useMemo(() => new SkittyBopSDK(), []);
    const [mediaStream, setMediaStream] = useState(new MediaStream());
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [connectedMembers, setConnectedMembers] = useState<IUsers[]>([]);
    const [isMicOn, setIsMicOn] = useState(false);
    const [isCamOn, setIsCamOn] = useState(false);
    const [incomingCallFrom, setIncomingCallFrom] = useState({
        from: '',
        offer: ''
    })
    const [outgoingCall, setOutgoingCall] = useState(false);

    const getMediaDevices = useCallback(async () => {
        const stream = await navigator.mediaDevices.getUserMedia(contraints);
        stream.getAudioTracks()[0].enabled = isMicOn;
        stream.getVideoTracks()[0].enabled = isCamOn;
        setMediaStream(stream);
        if (localStream.current) {
            localStream.current.srcObject = stream;
        }
    }, []);

    const remoteStreamListener = useCallback(async (stream: MediaStream) => {
        if (remoteStream.current) {
            remoteStream.current.srcObject = stream;
        }
    }, []);

    const serverConnected = useCallback(() => {
        getMediaDevices();
        const myUsername = prompt("what's your username?");
        // const myUsername ='arjil'
        const sessionId = localStorage.getItem(`${myUsername} sessionId`) ?? nanoid();
        if (webrtc.isConnected()) {
            webrtc.join(myUsername, sessionId);
            localStorage.setItem(`${myUsername} sessionId`, sessionId);

            webrtc.on("update-connected-members-list", (users: string[]) => {
                let tmp = users.map((iterator) => {
                    return {
                        user: iterator,
                        status: 'Online'
                    }
                })
                setConnectedMembers((prevConnectedMembers) =>
                    prevConnectedMembers.concat(tmp)
                );
            });
            webrtc.on("call-outgoing", (to: string, answer: "accepted" | "declined") => {
                console.log("call=outgoing")
                setOutgoingCall(false);
            })

            webrtc.on("call-incoming", (from: string, offer: string) => {
                console.log("call=incoming")
                setIncomingCallFrom({ from, offer })
                handleShow()
            })

            webrtc.on("update-member-status", (user: string, status: string) => {
                setConnectedMembers((prevConnectedMembers: IUsers[]) => {
                    let index = prevConnectedMembers.findIndex((iterator: IUsers) => user === iterator.user);
                    if (index >= 0) {
                        prevConnectedMembers[index].status = status;
                    }
                    return [...prevConnectedMembers]
                })
            })

            webrtc.on("toggle", (from: string, opts: any) => { console.log("toog=gle") })

            webrtc.on("error-handler", (err: Error) => { })

            webrtc.on("member-left", (user: string) => {
                setConnectedMembers((prevConnectedMembers) =>
                    prevConnectedMembers.filter((member) => member.user !== user)
                )

            });
        } else {
            console.log("in else")
        }
    }, []);

    useEffect(() => {
        webrtc.on("remote-stream", remoteStreamListener);
    }, [remoteStream]);

    useEffect(() => {
        webrtc.connect({ developerId: process.env.REACT_APP_DEVELOPER_ID, key: process.env.REACT_APP_API_KEY });
        webrtc.on("server-connection", serverConnected);
    }, []);

    useEffect(() => {
        if (localStream.current && localStream.current.srcObject) {
            const tmp = localStream.current.srcObject as MediaStream;
            tmp.getAudioTracks()[0].enabled = isMicOn;
            try {
                if (remoteStream.current.srcObject) {
                    const value = isMicOn ? 'on' : 'off';
                    webrtc.toggle({ mic: value })
                }
            }
            catch (e: any) { }
        }
    }, [isMicOn]);

    useEffect(() => {
        if (localStream.current && localStream.current.srcObject) {
            const tmp = localStream.current.srcObject as MediaStream;
            tmp.getVideoTracks()[0].enabled = isCamOn;
            try {
                if (remoteStream.current.srcObject) {
                    const value = isCamOn ? 'on' : 'off';
                    webrtc.toggle({ cam: value })
                }
            } catch (e: any) { }
        }
    }, [isCamOn]);

    const acceptIncomingCall = () => {
        webrtc.acceptCall(incomingCallFrom.from, incomingCallFrom.offer, mediaStream);
        handleClose();
    }

    const declineIncomingCall = () => {
        webrtc.declineCall(incomingCallFrom.from);
        handleClose();
    }

    return (
        <div>
            <Modal
                size="sm"
                show={show}
                onHide={declineIncomingCall}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton >
                    <Modal.Title>
                        <h5>INCOMING CALL from : {incomingCallFrom.from}</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row gap="2">
                        <Col>
                            <Button variant="danger" onClick={declineIncomingCall} className="w-100">
                                Reject
                            </Button>
                        </Col>
                        <Col>
                            <Button variant="success" onClick={acceptIncomingCall} className="w-100">
                                Accept
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <h1 className="text-center">Video Call</h1>
            <Container fluid>
                <Row>
                    <Col xs={2} sm={2} md={2} lg={2}>
                        <ul className="overflow-auto" style={{ maxHeight: '570px' }}>
                            {connectedMembers.map((user, index) => {
                                return (
                                    <li key={index} className='my-2 px-2 list-unstyled'>
                                        <p className='text-end'>
                                            {user.user} -  {user.status}&nbsp;
                                            <Button
                                                size="sm"
                                                onClick={() => {
                                                    setOutgoingCall(true);
                                                    webrtc.makeCall(user.user, mediaStream);
                                                }}
                                                disabled={user.status === 'Busy'}
                                            >
                                                Call
                                            </Button>
                                        </p>
                                    </li>
                                );
                            })}
                        </ul>
                    </Col>
                    <Col xs={5} sm={5} md={5} lg={5}>
                        <div className="position-relative parentdiv">
                            <video
                                ref={localStream}
                                id="videowindow"
                                autoPlay
                                muted
                                playsInline
                                className={"w-100 h-100"}
                            ></video>
                            <div className="position-absolute w-100 text-center overlaydiv">
                                <div className="d-flex justify-content-evenly">
                                    <Button variant="success" className="p-3" onClick={() => {
                                        setIsMicOn(!isMicOn);
                                    }}
                                        id="audio"
                                        size="sm"
                                    >
                                        {isMicOn ? "Mute" : "Unmute"}
                                    </Button>
                                    <Button
                                        size="sm"
                                        variant="info"
                                        className="p-3"
                                        onClick={() => {
                                            setIsCamOn(!isCamOn);
                                        }}
                                        id="video"
                                    >
                                        {isCamOn ? <span>video</span> : <s>video</s>}
                                    </Button>
                                    <Button
                                        size="sm"
                                        variant="danger"
                                        className="p-3"
                                        onClick={() => {
                                            webrtc.endCall();
                                        }}
                                        id="endcall"
                                    >
                                        end call
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={5} sm={5} md={5} lg={5}>

                        <div className="position-relative parentdiv">
                            {outgoingCall && (
                                <div className="position-absolute w-100 h-100 d-flex align-items-center" style={{ zIndex: 1 }}>
                                    <p className="w-100 text-light text-center">Loading</p>
                                </div>)}
                            <video ref={remoteStream} autoPlay playsInline className={"w-100 h-100"}></video>
                            <div className="position-absolute overlaydiv end-0">
                                <p className="overlaydiv2">This is toggle</p>
                            </div>
                        </div>

                        <div className="position-relative parentdiv">
                            {outgoingCall && (
                                <div className="position-absolute w-100 h-100 d-flex align-items-center" style={{ zIndex: 1 }}>
                                    <p className="w-100 text-light text-center">Loading</p>
                                </div>)}
                            <video ref={remoteStream} autoPlay playsInline className={"w-100 h-100"}></video>
                            <div className="position-absolute overlaydiv end-0">
                                <p className="overlaydiv2">This is toggle</p>
                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default App;

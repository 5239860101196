import { STUN_SERVER, TURN_SERVER } from "./config";
import { SignallingServer } from "./SignalingServer";

const iceServers = [
  {
    urls: STUN_SERVER,
  },
  {
    urls: TURN_SERVER['url'],
    username: TURN_SERVER['username'],
    credential: TURN_SERVER['password'],
  }
];

interface User {
  name: string;
  callPartner: { username: string, iceCandidates: any[] };
  stream: MediaStream;
  callState: 'incoming' | 'outgoing' | 'oncall';
  mic: 'on' | 'off';
  cam: 'on' | 'off';
}

export class SkittyBopSDK {
  private server: SignallingServer;
  private pc: RTCPeerConnection;
  private user: User;
  private registeredEvents: Record<string, any>;

  constructor() {
    this.registeredEvents = {};
    this.user = { callPartner: { iceCandidates: [] } } as User;
  }

  /**
   * connect to signaling server
   * @param config developer configuration
   */
  public connect = (config: { developerId: any, key: any }) => {
    if (!(config.developerId && config.key)) {
      throw new Error('Developer Credentials missing')
    }

    this.server = new SignallingServer(config.developerId, config.key);
    this.registerEvents();
  };

  /**
   * check if the user is connected
   * @returns boolean
   */
  public isConnected = () => {
    return this.server.connected;
  };

  /**
   * Register your event
   * @param event Event name
   * @param fn Event listener
   */
  public on = (event: string, fn: any) => {
    const allowedEvents = ["server-connection", "update-connected-members-list", "update-member-status", "remote-stream", "member-left", "toggle", "call-outgoing", "call-incoming", "error-handler"];
    if (allowedEvents.includes(event)) {
      this.registeredEvents[event] = fn;
    } else {
      console.log(`event ${event} is not allowed to register`);
    }
  };

  private registerEvents = () => {
    this.server.on("member-joined", ({ username }: { username: string }) => {
      console.log('member-joined: ', username);
      if (this.registeredEvents["update-connected-members-list"]) {
        const eventHandler =
          this.registeredEvents["update-connected-members-list"];
        eventHandler([username]);
      }
    });

    this.server.on("member-left", ({ username }: { username: string }) => {
      console.log(`${username} left the server`);
      if (this.registeredEvents["member-left"]) {
        const eventHandler =
          this.registeredEvents["member-left"];
        eventHandler(username);
      }
    });

    this.server.on("member-status", ({ username, status }: { username: string, status: string }) => {
      console.log(`${username} status updated`);
      if (this.registeredEvents["update-member-status"]) {
        const eventHandler =
          this.registeredEvents["update-member-status"];
        eventHandler(username, status);
      }
    });

    this.server.on("connected-members", ({ users }: { users: [string] }) => {
      console.log('connected-members: ', users);
      if (this.registeredEvents["update-connected-members-list"]) {
        const eventHandler =
          this.registeredEvents["update-connected-members-list"];
        eventHandler(users);
      }
    });

    this.server.on("connection", (state: boolean) => {
      console.log('connection event');
      if (this.registeredEvents["server-connection"]) {
        const eventHandler =
          this.registeredEvents["server-connection"];
        eventHandler(state);
      }
    });

    this.server.on("error", ({ msg }: { msg: string }) => {
      console.log('error occured');
      if (this.registeredEvents["error-handler"]) {
        const eventHandler =
          this.registeredEvents["error-handler"];
        eventHandler(new Error(msg));
      }
    });

    this.server.on("make-call", ({ from, offer }: any) => {
      console.log(`Listening make-call event`);
      console.log('offer: ', offer);
      this.user["callState"] = 'incoming';

      if (this.registeredEvents["call-incoming"]) {
        const eventHandler =
          this.registeredEvents["call-incoming"];
        eventHandler(from, offer);
      }
    });

    this.server.on("accept-call", ({ answer }: any) => {
      console.log(`Listening accept-call event`);
      this.user["callState"] = "oncall";
      this.addAnswer(answer);

      if (this.registeredEvents["call-outgoing"]) {
        const eventHandler =
          this.registeredEvents["call-outgoing"];
        eventHandler(this.user.callPartner["username"], "accepted");
      }
    });

    this.server.on("decline-call", ({ from }: any) => {
      console.log(`Listening decline-call event`);
      if (this.registeredEvents["call-outgoing"]) {
        const eventHandler =
          this.registeredEvents["call-outgoing"];
        eventHandler(from, "declined");
      }

      this.calldeclined();
    });

    this.server.on("call-signal", ({ candidate }: any) => {
      console.log(`Listening call-signal event`);
      this.addCandidate(candidate);
    });

    this.server.on("end-call", ({ from }: any) => {
      console.log(`${from} ended call`);
      this.endCall();
    });

    this.server.on("toggle", ({ from, opts }: any) => {
      console.log(`${from} toggling`);
      if (this.registeredEvents["toggle"]) {
        const eventHandler =
          this.registeredEvents["toggle"];
        eventHandler(from, opts);
      }
    });

    this.server.on("live-status", ({ username, status }: { username: string, status: string }) => {
      console.log(`${username} live status : ${status}`);
    });
  };

  private RTCEventListener = () => {
    this.pc.onicecandidate = (event: any) => {
      console.log(`emitting call signal: `, event.candidate)
      if (event.candidate) {
        this.server.emit("call-signal", {
          from: this.user["name"],
          to: this.user.callPartner["username"],
          candidate: event.candidate,
        });
      }
    };

    this.pc.ontrack = (event) => {
      console.log('adding remote track: ', event.streams[0])
      if (this.registeredEvents["remote-stream"]) {
        const eventHandler = this.registeredEvents["remote-stream"];
        eventHandler(event.streams[0]);
      }
    }
  };

  /**
   * Join the server
   * @param username name of user
   * @param sessionId session id of user
   */
  public join = (username: string, sessionId: any) => {
    if (!(username && sessionId)) {
      throw new Error('Invalid username');
    }
    if (!this.isConnected()) {
      throw new Error('Not connected to server');
    }

    this.user["name"] = username;
    this.server.emit("join", { username, sessionId });
  };

  private addLocalStream = (stream: MediaStream) => {
    console.log('adding local stream: ', stream)
    this.user["stream"] = stream;
    const tracks = stream.getTracks();
    for (const track of tracks) {
      this.pc.addTrack(track, stream);
    }
  };

  /**
   * start call
   */
  public makeCall = async (to: string, stream: MediaStream) => {
    console.log(`calling ${to}`)
    if (this.user["callState"]) {
      throw new Error("Call not allowed");
    }

    this.user.callPartner["username"] = to;
    this.user["mic"] = 'on';
    this.user["cam"] = 'on';
    this.user["callState"] = 'outgoing';

    this.pc = new RTCPeerConnection({ iceServers });
    this.RTCEventListener();
    this.addLocalStream(stream);
    const offer = await this.pc.createOffer();
    console.log('offer: ', offer);
    await this.pc.setLocalDescription(offer);
    this.server.emit("make-call", {
      from: this.user["name"],
      to: this.user.callPartner["username"],
      offer,
    });
  };

  /**
   * answer the call 
   */
  public acceptCall = async (from: string, offer: any, stream: MediaStream) => {
    console.log(`accepting call from ${from}`)
    if (this.user["callState"] !== "incoming") {
      throw new Error("No incoming call");
    }

    this.user.callPartner["username"] = from;
    this.user["callState"] = "oncall";
    this.pc = new RTCPeerConnection({ iceServers });
    this.RTCEventListener();
    this.addLocalStream(stream);
    await this.pc.setRemoteDescription(offer);
    this.addCandidate();
    const answer = await this.pc.createAnswer();
    console.log('answer: ', answer);
    await this.pc.setLocalDescription(answer);
    this.server.emit("accept-call", {
      from: this.user["name"],
      to: this.user.callPartner["username"],
      answer,
    });
  };

  /**
   * decline the call 
   */
  public declineCall = async (from: string) => {
    console.log(`declining call from ${from}`)
    if (this.user["callState"] !== "incoming") {
      throw new Error("No incoming call");
    }

    this.server.emit("decline-call", {
      from: this.user["name"],
      to: from
    });
    this.user.callPartner["iceCandidates"] = [];
    this.user["callState"] = null;
  };

  private addAnswer = async (answer: any) => {
    console.log('setting remote description');
    console.log('answer: ', answer);
    await this.pc.setRemoteDescription(answer);
  };

  private calldeclined = () => {
    console.log('handling call declined');
    this.user.callPartner["username"] = null;
    this.user["stream"] = null;
    this.user["callState"] = null;
    this.user["mic"] = null;
    this.user["cam"] = null;

    this.pc = null;

  };

  private addCandidate = async (candidate?: any) => {
    if (this.pc) {
      const iceCandidates = this.user.callPartner["iceCandidates"];
      if (iceCandidates.length > 0) {
        for (const candidate of iceCandidates) {
          console.log('adding ice candidate: ', candidate)
          await this.pc.addIceCandidate(candidate);
        }
        this.user.callPartner["iceCandidates"] = [];
      }
      if (candidate) {
        console.log('adding ice candidate: ', candidate)
        await this.pc.addIceCandidate(candidate);
      }
    } else {
      console.log('storing candidate: ', candidate)
      this.user.callPartner["iceCandidates"].push(candidate);
    }
  };

  /**
   * hang up your call
   */
  public endCall = () => {
    if (this.user["callState"] !== "oncall") {
      throw new Error("No ongoing call")
    }

    this.server.emit("end-call", { from: this.user["name"], to: this.user.callPartner["username"] });
    this.user.callPartner["username"] = null;
    this.user.callPartner["iceCandidates"] = [];
    this.user["stream"] = null;
    this.user["callState"] = null;
    this.user["mic"] = null;
    this.user["cam"] = null;

    this.pc.close();
    this.pc = null;
  };

  /**
   * Toggle your mic or camera
   */
  public toggle = (opts: { mic?: 'on' | 'off', cam?: 'on' | 'off' }) => {
    if (this.user["callState"] !== "oncall") {
      throw new Error("No ongoing call")
    }

    let isMicToggled = false, isCamToggled = false;
    // let audiotracks = this.user["stream"].getAudioTracks();
    // let videotracks = this.user["stream"].getVideoTracks();
    // let tracks = [];

    if (opts.mic && opts.mic !== this.user["mic"]) {
      isMicToggled = true;
      this.user["mic"] = opts.mic;
      this.user["stream"].getAudioTracks()[0].enabled = this.user["mic"] === 'on' ? true : false;
      // if (this.user["mic"] === 'on') {
      //   tracks.push(...audiotracks);
      // }
    }
    if (opts.cam && opts.cam !== this.user["cam"]) {
      isCamToggled = true;
      this.user["cam"] = opts.cam;
      this.user["stream"].getVideoTracks()[0].enabled = this.user["cam"] === 'on' ? true : false;
      // if (this.user["cam"] === 'on') {
      //   tracks.push(...videotracks);
      // }
    }

    // if (tracks.length !== 0) {// what if both cam & mic turned off
    //   for (const track of tracks) {
    //     this.pc.addTrack(track, this.user["stream"]);
    //   }
    // }

    if (isMicToggled || isCamToggled) {
      this.server.emit("toggle", { from: this.user["name"], to: this.user.callPartner["username"], opts });
    }
  };

  public getliveStatus = (username: string) => {
    this.server.emit("live-status", { username });
  };
}

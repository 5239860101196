import { io, Socket } from "socket.io-client";
import { SIGNALING_SERVER } from './config';

export class SignallingServer {
    private socket: Socket;
    private registeredEvents: Record<string, any>;
    public connected: boolean;

    constructor(developerId: any, key: any) {
        this.registeredEvents = {}
        this.socket = io(`${SIGNALING_SERVER}/signal/${developerId}`, {
            auth: {
                key
            }
        });

        this.socket.on("connect", () => {
            console.log(this.socket.id);
            this.connected = this.socket.connected;
            if (this.registeredEvents["connection"]) {
                const eventHandler = this.registeredEvents["connection"];
                eventHandler(this.connected);
            }
        });

        this.socket.on("disconnect", () => {
            console.log(this.socket.id);
            this.connected = this.socket.connected;
            if (this.registeredEvents["connection"]) {
                const eventHandler = this.registeredEvents["connection"];
                eventHandler(this.connected);
            }
        });

        this.socket.on("connect_error", (err) => {
            console.log(err.message);
        });

        this.socket.onAny((eventName, ...args) => {
            if (this.registeredEvents[eventName]) {
                const eventHandler = this.registeredEvents[eventName];
                eventHandler(...args);
            }
        });

    }

    public on = (event: string, fn: any) => {
        const allowedEvents = ["connection", "member-joined", "member-status", "member-left", "make-call", "accept-call", "decline-call", "call-signal", "connected-members", "end-call", "toggle", "live-status", "error"];
        if (allowedEvents.includes(event)) {
            this.registeredEvents[event] = fn;
        } else {
            console.log(`event ${event} is not allowed to register`);
        }
    }

    public emit = (event: string, data: any) => {
        const allowedEvents = ["join", "make-call", "accept-call", "decline-call", "call-signal", "end-call", "toggle", "live-status"];
        if (allowedEvents.includes(event)) {
            this.socket.emit(event, { ...data });
        } else {
            console.log(`event ${event} is not allowed to emit`);
        }
    }
}
